import { ComponentProps } from 'react';

import { css } from '@emotion/react';
import {
  Dialog,
  DialogContent as MuiDialogContent,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import  CloseOutlined  from '@mui/material/Icon';
import tw from 'twin.macro';

import { PopupSize } from '@interfaces';

const DialogTitle = ({ children, onClose }) => {
  return (
    <Box css={tw`h-14 flex items-center px-6`}>
      <Typography
        variant="h2"
        css={tw`flex-1 p-0 font-poppins text-base text-navy font-medium [letter-spacing:0.44px]`}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton onClick={onClose} css={tw`-mr-4`}>
          <CloseOutlined css={tw`text-navy fill-current`} />
        </IconButton>
      ) : null}
    </Box>
  );
};

export const Popup = ({
  onClose,
  title,
  open,
  children,
  body,
  size = PopupSize.SMALL,
}: { size?: PopupSize; body?: string } & ComponentProps<typeof Dialog>) => {
  const width =
    // eslint-disable-next-line no-nested-ternary
    size === PopupSize.LARGE
      ? '960px'
      : size === PopupSize.MEDIUM
        ? '640px'
        : '480px';

  const cssStyles = [
    tw`rounded-lg bg-green-vogue bg-opacity-[0.03]  py-4 px-2`,
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      css={css`
        .MuiDialog-paper {
          width: ${width};
          box-shadow:
            0 12px 24px 0 RGBA(var(--trueblack), 0.1),
            inset 0 0 0 99999px RGBA(var(--overlay), 0.16);
          border-radius: 16px;
        }
      `}
      maxWidth={false}
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <MuiDialogContent css={tw`p-6 pt-0`}>
        {body ? (
          <div css={cssStyles} dangerouslySetInnerHTML={{ __html: body }} />
        ) : (
          <Box css={cssStyles}>{children}</Box>
        )}
      </MuiDialogContent>
    </Dialog>
  );
};
