import gql from 'graphql-tag';

import { getTeddlySdkApi } from '@config/teddly-sdk';
import { ActivePopup } from '@interfaces';

type BlockTypeParagraph = {
  type: 'paragraph';
  data: {
    text: string;
  };
};

type BlockTypeHeader = {
  type: 'header';
  data: {
    text: string;
    level: number;
  };
};

type BlockTypeList = {
  type: 'list';
  data: {
    style: 'ordered' | 'unordered';
    items: string[];
  };
};

type BlockTypeQoute = {
  type: 'quote';
  data: {
    text: string;
    caption: string;
    alignment: 'left' | 'center';
  };
};

type ParsedBodyType = {
  time: number;
  blocks: Array<
    BlockTypeParagraph | BlockTypeHeader | BlockTypeList | BlockTypeQoute
  >;
};

function parsePopupBody(body: string): string {
  let string = '';

  try {
    const parsedBody = JSON.parse(body) as ParsedBodyType;
    parsedBody.blocks.forEach((block) => {
      if (block.type === 'paragraph') {
        string += `<p style="margin-bottom:10px">${block.data.text}</p>`;
      } else if (block.type === 'header') {
        string += `<h${
          block.data.level
        } style="margin-bottom:10px;margin-top:10px;font-weight:bold;font-size:${
          5 - block.data.level
        }rem">${block.data.text}</h${block.data.level}>`;
      } else if (block.type === 'list') {
        const listType = block.data.style === 'ordered' ? 'ol' : 'ul';
        string += `<${listType} style="margin-bottom:10px;padding:revert;list-style:auto">`;
        block.data.items.forEach((item) => {
          string += `<li style="margin-bottom:10px">${item}</li>`;
        });
        string += `</${listType}>`;
      } else if (block.type === 'quote') {
        string += `
        <figure style="margin-bottom:10px">
        <blockquote>"${block.data.text}"</blockquote>
        <figcaption style="text-align:${block.data.alignment}">-- ${block.data.caption}</figcaption>
        </figure>`;
      }
    });
  } catch (e) {
    console.error('Popup body parse error', e);
  }
  return string;
}

export async function getActivePopups(): Promise<ActivePopup[]> {
  const { teddlyApolloClient } = await getTeddlySdkApi();

  return teddlyApolloClient
    .query({
      query: gql`
        query GetActivePopups {
          activePopups(first: 10, includeAllChannels: true) {
            edges {
              node {
                id
                body
                title
                channel {
                  id
                  name
                  slug
                }
                showOnCategory {
                  slug
                }
                showOnHomepage
                boxSize
                startDate
                endDate
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => {
      const activePopups: ActivePopup[] = [];
      data?.activePopups?.edges?.forEach(({ node }) => {
        const {
          id,
          body,
          showOnCategory,
          showOnHomepage,
          title,
          boxSize,
          startDate,
          endDate,
          channel
        } = node;

        activePopups.push({
          id,
          body: parsePopupBody(body),
          page: showOnHomepage ? 'home' : showOnCategory?.slug,
          boxSize,
          title,
          startDate,
          endDate,
          channel
        });
      });
      return activePopups;
    });
}
